import React from 'react'
import './style.scss'

const Accessibility = () => {
  return (
    <div className="accessibility-section container section">
      <div className="text-center">
        <h1 className="title work-sans">
          <strong>ACCESSIBILITY STATEMENT</strong>
        </h1>
        <p className="mt-3">
          <strong>Vision Path, Inc.</strong>
        </p>
      </div>

      <p>
        <strong>Accessibility Statement for Hubble Contacts and ContactsCart</strong>
      </p>
      <p className="legal-text">
        Vision Path, Inc., which operates hubblecontacts.com and contactscart.com, believes that everyone should be able to use our websites. Our goal is to meet or exceed the requirements of the Web Content Accessibility Guidelines (WCAG) 2.1 Level A/AA so that everyone, including those using assistive technologies, can successfully gather information and transact business through our websites.
        While we have devoted substantial resources to improve the accessibility of our websites, our efforts are a work in progress, and we are working towards our goal of WCAG 2.1 A/AA compliance over time. To help us, we have engaged an external consulting firm, The Paciello Group (TPG), to conduct both manual and ongoing automated testing of our websites and to provide us with specific recommendations about how to improve the accessibility of our websites. 
      </p>

      <p>
        <strong>Feedback</strong>
      </p>
      <p className="legal-text">
        We are continually seeking to improve the user experience for all of our visitors, and we welcome your feedback on the accessibility of the Hubble and ContactsCart websites. Since our accessibility efforts are ongoing, your feedback will help us identify aspects of our website that could be improved. 
        If you are experiencing any barriers to accessing our websites, please contact us at accessibility@hubblecontacts.com or accessibility@contactscart.com.
      </p>
  </div>
  )
}

export default Accessibility
import React, { useEffect } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Accessibility from "../components/Accessibility"

const AccessibilityStatement = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('dots')
    }
  }, [])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/accessibility-statement",
      "@type": "webpage",
      "name": "Hubble Contacts Accessibility Statement | Hubble",
      "description": "Hubble contacts believes everyone should be able to use our website. Learn more about our efforts to meet or exceed the compliance requirements of Web Content Accessibility Guidelines (WCAG) 2.1 Level A/AA.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    }
  ]



  return (
    <Layout>
      <SEO title="Hubble Contacts Accessibility Statement"
        description="Hubble contacts believes everyone should be able to use our website. Learn more about our efforts to meet or exceed the compliance requirements of Web Content Accessibility Guidelines (WCAG) 2.1 Level A/AA."
        schemaMarkup={schemaMarkup}
      />
      <Accessibility />
    </Layout>
  )
}

export default AccessibilityStatement
